<template>
  <div class="billion-acts">
    <!--header section start-->
    <header class="header">
      <nav class="navbar navbar-expand-lg fixed-top bg-transparent">
        <div class="container">
          <a class="navbar-brand" href="https://www.billionacts.org">
            <img src="../../assets/billionacts/img/logo-white.png" alt="logo" class="img-fluid" />
          </a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#menubar" aria-controls="menubar" aria-expanded="false" aria-label="Toggle navigation">
            <span class="ti-menu"></span>
          </button>
          <div class="collapse navbar-collapse h-auto" id="menubar">
            <ul class="navbar-nav ml-auto menu">
              <li>
                <a href="/" class="page-scroll">Home</a>
              </li>

              <li>
                <a href="#about" class="page-scroll" @click="pageScroll">About Us</a>
              </li>

              <li>
                <a href="#upgrades" class="page-scroll" @click="pageScroll">Upgrades</a>
              </li>

              <li>
                <a href="#benefits" class="page-scroll" @click="pageScroll">Benefits</a>
              </li>

              <li>
                <a href="#status" class="page-scroll" @click="pageScroll">Status</a>
              </li>

              <li>
                <a href="#partners" class="page-scroll" @click="pageScroll">Partners</a>
              </li>

              <li>
                <a href="#footer" class="page-scroll" @click="pageScroll">Subscribe</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
    <!--header section end-->
    <!--body content wrap start-->
    <div class="main">
      <!--banner section start-->
      <section id="home" class="hero-equal-height ptb-100 gradient-overlay">
        <div class="container">
          <div class="row align-items-center justify-content-between">
            <div class="col-md-7 col-lg-6">
              <div class="hero-content-left text-white">
                <h1 class="text-white">1 Billion Acts Of Peace Can Change The World</h1>
                <p class="lead">The PeaceJam Organization has created a platform for social good where individuals and organizations can support each other in creating positive change in our world</p>
              </div>
            </div>
            <div class="col-md-4 col-lg-4">
              <div class="video-promo-content mt-4 text-center">
                <a href="https://player.vimeo.com/video/140371275?title=0&byline=0&portrait=0" class="popup-youtube video-play-icon"><span class="ti-control-play"></span></a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--banner section end-->
      <!--Three blocks section new start-->
      <section class="promo-new mt--120">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-lg-4" data-aos="fade-up" data-aos-duration="2000">
              <div class="new-single-promo rounded p-4 border-top-0 mt-4 white-bg text-center">
                <div class="promo-img-wrap mb-3">
                  <img src="../../assets/billionacts/img/icon.png" width="50" alt="promo" class="img-fluid" />
                </div>
                <div class="promo-content-wrap">
                  <h4>Act of Peace</h4>
                  <p>An individual can create a thoughtful action that spreads more peace for a community, school, business or organization and others can participate in this action while sharing the progress in their public user profile.</p>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-lg-4" data-aos="fade-up" data-aos-duration="1000">
              <div class="new-single-promo rounded p-4 border-top-0 mt-4 white-bg text-center">
                <div class="promo-img-wrap mb-3">
                  <img src="../../assets/billionacts/img/icon.png" width="50" alt="promo" class="img-fluid" />
                </div>
                <div class="promo-content-wrap">
                  <h4>Network Member​</h4>
                  <p>An organization can join as a network member which includes the ability to be featured on the website and social media channels while also having access to a microsite and build an audience of participants for their acts and campaigns.</p>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-lg-4" data-aos="fade-up" data-aos-duration="2000">
              <div class="new-single-promo rounded p-4 border-top-0 mt-4 white-bg text-center">
                <div class="promo-img-wrap mb-3">
                  <img src="../../assets/billionacts/img/icon.png" width="50" alt="promo" class="img-fluid" />
                </div>
                <div class="promo-content-wrap">
                  <h4>Campaign</h4>
                  <p>A registered user can create a campaign that allows an organization to mobilize participants around an event, goal, or create an impact in one of the Billion Acts Issue Areas and work to achieve it together.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--Three blocks section new end-->
      <!--About Us section start-->
      <section id="about" class="about_sec">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-6 col-sm-12" data-aos="fade-right" data-aos-duration="1500">
              <img alt="Image" src="../../assets/billionacts/img/banner4.jpg" class="img-fluid" />
            </div>
            <div class="col-md-6 col-sm-12">
              <h2 class="mb-2">Our Mission</h2>
              <p>The PeaceJam Organization partnered with Ocean Ring Technologies to redesign and build the new Billion Acts platform. Our mission was to consolidate all of the organization's data into a more streamlined and efficient architecture.</p>

              <p><b>And to be able to build more user-centric workflows for an audience consisting of both youth and adults. The collaboration allows ORT to continue to provide technical assistance to social good organizations and help make a difference in our world through the use of technology.</b></p>
            </div>
          </div>
        </div>
      </section>
      <!--About Us section end-->
      <!--call to action section start-->
      <section id="" class="calltoaction">
        <div class="container">
          <div class="row">
            <h3>The platform maintains over <b>10,000</b> new Acts of Peace a month</h3>
          </div>
        </div>
      </section>
      <!--call to action section end-->
      <!--Impact section start-->
      <section id="" class="about_sec">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-6 col-sm-12">
              <h2 class="mb-2">Our Architecture</h2>
              <p>The data was migrated from Google datastore to a SQL Server relational database running on the Azure platform.</p>

              <p>Utilizing Microsoft's .Net Core framework, the API provides the frontend with the requested data as well as allow other entities a way to integrate into the Billion Acts system for tracking of impact.</p>

              <p>The frontend design is custom and uses the Angular Javascript Framework for a fluid and efficient user experience.</p>

              <h5>
                <b>"The architecture provided by the ORT team has been a major improvement in allowing us to reach more youth and partners."</b>
              </h5>

              <p>
                <b>-PeaceJam Organization</b>
              </p>
            </div>

            <div class="col-md-6 col-sm-12" data-aos="fade-left" data-aos-duration="1500">
              <img alt="Image" src="../../assets/billionacts/img/banner3.jpg" class="img-fluid" />
            </div>
          </div>
        </div>
      </section>
      <!--Impact section end-->
      <!--members section start-->
      <section id="upgrades" class="member-section gradient-overlay" style="background: url('.../../../assets/billionacts/img/banner2.jpg')no-repeat center center / cover fixed">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-9 col-lg-8">
              <div class="section-heading text-center text-white mb-5">
                <h2 class="text-white">Upgrades</h2>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-lg-4 col-md-4 col-sm-12" data-aos="fade-up" data-aos-duration="2000">
              <div class="card">
                <a href="http://www.compassiongames.org/mlk-40-days/">
                            <span class="avatar">
                                <img src="../../assets/billionacts/img/member-1.png" class="img-fluid">
                            </span>
                  <div class="card_desc">
                    <p>High Performance API</p>
                  </div>
                </a>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12" data-aos="fade-up" data-aos-duration="1000">
              <div class="card">
                <a href="http://www.compassiongames.org/mlk-40-days/">
                            <span class="avatar">
                                <img src="../../assets/billionacts/img/member-2.png" class="img-fluid">
                            </span>
                  <div class="card_desc">
                    <p>Reliable Azure Hosting</p>
                  </div>
                </a>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12" data-aos="fade-up" data-aos-duration="1000">
              <div class="card">
                <a href="http://www.compassiongames.org/mlk-40-days/">
                            <span class="avatar">
                                <img src="../../assets/billionacts/img/member-3.png" class="img-fluid">
                            </span>
                  <div class="card_desc">
                    <p>Nomalized Relational SQL Database</p>
                  </div>
                </a>
              </div>
            </div>
          </div>


        </div>
      </section>
      <!--members section end-->
      <!--Benefits section start-->
      <section id="benefits" class="benefits_sec">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
              <h2>Features of the Site Rebuild</h2>

              <ul class="main_list">
                <li>
                  <p>Improved site design and responsive styling for better viewing on multiple platforms</p>
                </li>

                <li>
                  <p>API implementation to provide the ability to share data with pre-approved partners</p>
                </li>

                <li>
                  <p>More data points for reporting of impact on acts, projects, campaigns and network members</p>
                </li>

                <li>
                  <p>
                    <b>Efficient forms for users to easily perform the following:</b>
                  </p>

                  <ol>
                    <li>
                      <p>Register and log in to the platform</p>
                    </li>

                    <li>
                      <p>Create an act of peace to influence others to participate</p>
                    </li>

                    <li>
                      <p>Increase in the data points captured for a campaign</p>
                    </li>

                    <li>
                      <p>Faster processing of network member applications</p>
                    </li>
                  </ol>
                </li>
              </ul>
            </div>

            <div class="col-lg-4 col-md-12 img_sec" data-aos="fade-left" data-aos-duration="1500">
              <img src="../../assets/billionacts/img/banner5.png" class="img-fluid" alt="/">
            </div>
          </div>
        </div>
      </section>
      <!--Benefits section end-->
      <!--Status section start-->
      <section id="status" class="status_sec">
        <div class="container">
          <div class="row" data-aos="fade" data-aos-duration="1500">
            <div class="stats_wrapper stats1">
              <div class="stats_icon">
                <img alt="" src="../../assets/billionacts/img/laurel.png">
              </div>
              <div class="stats_text">
                <h1>14</h1>
                NOBEL PEACE LAUREATES
              </div>
            </div>

            <div class="stats_wrapper stats2">
              <div class="stats_icon">
                <img alt="" src="../../assets/billionacts/img/conference.png">
              </div>
              <div class="stats_text">
                <h1>350</h1>
                YOUTH CONFERENCES
              </div>
            </div>

            <div class="stats_wrapper stats3">
              <div class="stats_icon">
                <img alt="" src="../../assets/billionacts/img/youth-engaged.png">
              </div>
              <div class="stats_text">
                <h1>1.3M</h1>
                YOUTH ENGAGED
              </div>
            </div>

            <div class="stats_wrapper stats4">
              <div class="stats_icon">
                <img alt="" src="../../assets/billionacts/img/project.png">
              </div>
              <div class="stats_text">
                <h1>+67M</h1>
                PEACE PROJECTS
              </div>
            </div>

            <div class="stats_wrapper stats5">
              <div class="stats_icon">
                <img alt="" src="../../assets/billionacts/img/Nobel-legacy.png">
              </div>
              <div class="stats_text">
                <h1>60K</h1>
                NOBEL LEGACY FILM VIEWS
              </div>
            </div>

            <div class="stats_wrapper stats6">
              <div class="stats_icon">
                <img alt="" src="../../assets/billionacts/img/viral.png">
              </div>
              <div class="stats_text">
                <h1>1.2M</h1>
                SOCIAL MEDIA REACH
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--Status section end-->
      <!--Help us section start-->
      <section id="contactus" class="contactus_sec">
        <div class="container">
          <div class="row">
            <h2>Help us reach one billion acts of peace</h2>

            <a href="https://www.billionacts.org/" class="solid-white-btn btn">Join Us</a>
          </div>
        </div>
      </section>
      <!--Help us section end-->
      <!--Logos section start-->
      <section id="partners" class="member_logos">
        <div class="container">
          <div class="row">
            <h2 class="mb-4">Billion Acts Partners</h2>
            <carousel
                    class="member_carousel owl-carousel owl-theme"
                    :dots="false"
                    :responsive="{0:{items:1},600:{items:2},900:{items:4}}"
                    :loop="true"
                    :autoplay="true"
            >
              <div class="item">
                <a href="https://www.billionacts.org/members">
                  <img alt="Image" src="../../assets/billionacts/img/google-logo.png" class="img-fluid" />
                </a>
              </div>

              <div class="item">
                <a href="https://www.billionacts.org/members">
                  <img alt="Image" src="../../assets/billionacts/img/peacejam-logo.png" class="img-fluid" />
                </a>
              </div>

              <div class="item">
                <a href="https://www.billionacts.org/members">
                  <img alt="Image" src="../../assets/billionacts/img/points_guy.png" class="img-fluid" />
                </a>
              </div>

              <div class="item">
                <a href="https://www.billionacts.org/members">
                  <img alt="Image" src="../../assets/billionacts/img/day-without-hate.png" class="img-fluid" />
                </a>
              </div>
            </carousel>
          </div>
        </div>
      </section>
      <!--Logos section end-->

    </div>
    <!--body content wrap end-->
    <!--footer section start-->
    <footer id="footer" class="footer-section">
      <div class="footer-top gradient-bg">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-12">
              <div class="footer-nav-wrap text-white mb-0 mb-md-4 mb-lg-0">
                <a class="d-block" href="/Projects">
                  <img src="../../assets/billionacts/img/logo.png" alt="footer logo" width="170" class="img-fluid mb-1">
                </a>
                <ul class="list-unstyled mb-0 text-white">
                  <li>
                    <span class="ti-location-pin"></span> P.O. Box 1100 Bala Cynwyd, PA
                  </li>
                  <li>
                    <span class="ti-email"></span> &#105;&#110;&#102;&#111;&#64;&#111;&#99;&#101;&#97;&#110;&#114;&#105;&#110;&#103;&#116;&#101;&#99;&#104;&#46;&#99;&#111;&#109;
                  </li>
                  <li>
                    <span class="ti-mobile"></span> &#40;&#56;&#53;&#54;&#41;&#32;&#52;&#52;&#56;&#45;&#52;&#51;&#54;&#56;
                  </li>
                </ul>

                <ul class="social-list list-unstyled mt-3 mb-0">
                  <li class="list-inline-item">
                    <a class="rounded" href="https://www.facebook.com/oceanringtech/" target="_blank">
                      <span class="ti-facebook white-bg color-2 shadow rounded-circle"></span>
                    </a>
                  </li>

                  <li class="list-inline-item">
                    <a class="rounded" href="https://twitter.com/oceanringtech" target="_blank">
                      <span class="ti-twitter-alt white-bg color-2 shadow rounded-circle"></span>
                    </a>
                  </li>

                  <li class="list-inline-item">
                    <a class="rounded" href="https://www.linkedin.com/company/14395475" target="_blank">
                      <span class="ti-linkedin white-bg color-2 shadow rounded-circle"></span>
                    </a>
                  </li>

                  <li class="list-inline-item">
                    <a class="rounded" href="https://www.instagram.com/oceanringtech/" target="_blank">
                      <span class="ti-instagram white-bg color-2 shadow rounded-circle"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-lg-3 col-md-4 col-12">
              <div class="footer-nav-wrap text-white">
                <h5 class="text-white">About</h5>
                <p class="mb-0 text-white">The One Billion Acts of Peace campaign launched in partnership with Google in May 2014 to provide a platform and tools for each of us to be able to create incremental and impactful change in our own backyards.</p>
              </div>
            </div>

            <div class="col-lg-2 col-md-4 col-12">
              <div class="footer-nav-wrap text-white">
                <h5 class="text-white">Company</h5>
                <p class="mb-0 text-white">We are a software solutions company helping clients develop, manage and modernize applications to accelerate their digital transformation and business results.</p>
              </div>
            </div>



            <div class="col-lg-4 col-md-4 col-12">
              <div class="footer-nav-wrap text-white">
                <h5 class="text-light footer-head">Newsletter</h5>
                <p>Subscribe to our newsletter to keep up to date. We will not send you spam email.</p>
                <form action="#" class="newsletter-form mt-3">
                  <div class="input-group">
                    <input type="email" class="form-control" id="email" placeholder="Enter your email" required="">
                    <div class="input-group-append">
                      <button class="btn solid-btn subscribe-btn btn-hover" type="submit">
                        Subscribe
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              <div class="d-block mt-4">
                <h3 class="text-white">Billion Acts of Peace</h3>
                <p class="mb-0 text-white">&copy; Copyrights {{new Date().getFullYear()}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!--footer section end-->
    <!--bottom to top button start-->
    <button class="scroll-top scroll-to-target" data-target="html">
      <span class="ti-angle-up"></span>
    </button>
    <!--bottom to top button end-->

  </div>
</template>

<script>
  import $ from "jquery";
  import carousel from 'vue-owl-carousel';

  export default {
    name: "billionacts",
    components: {
      carousel
    },
    methods: {
      pageScroll(e) {
        e.preventDefault();
        let attr = e.target.getAttribute("href");
        let element = document.querySelector(attr);
        if (element) {
          element.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
        }
      },
    },
    created() {
      window.scrollTo(0, 0);
    },
    mounted() {
      $(window).on('scroll', function () {
        // checks if window is scrolled more than 500px, adds/removes solid class
        if ($(this).scrollTop() > 58) {
          $('.navbar').addClass('affix');
          $('.scroll-to-target').addClass('open');
        } else {
          $('.navbar').removeClass('affix');
          $('.scroll-to-target').removeClass('open');
        }
      });

      if ($('.scroll-to-target').length) {
        $(".scroll-to-target").on('click', function () {
          var target = $(this).attr('data-target');
          // animate
          $('html, body').animate({
            scrollTop: $(target).offset().top
          }, 500);

        });
      }
    }
  }
</script>

<style scoped>
  @import url('../../assets/billionacts/css/bootstrap.min.css');
  @import url('../../assets/billionacts/css/magnific-popup.css');
  @import url('../../assets/billionacts/css/themify-icons.css');
  @import url('../../assets/billionacts/css/style.css');
  @import url('../../assets/billionacts/css/responsive.css');


  #home {
    background: url('../../assets/billionacts/img/banner.jpg')no-repeat center center / cover
  }

  .owl-carousel {
    display: inherit;
  }
</style>
